<template>
    <div class="wrapper">
        <homehead />
        <div class="home-breadcrumb">
            <el-breadcrumb separator="/">
                <el-breadcrumb-item :to="{ path: '/' }">首页</el-breadcrumb-item>
                <el-breadcrumb-item><a href="/notice" style="cursor:pointer">公告</a></el-breadcrumb-item>
            </el-breadcrumb>
        </div>
        <div class="notice-detail" v-if="showNoticeDetail">
            <p class="notice-detail-title">{{ currentDetail.title }}</p>
            <p style="margin-top:8px">{{ currentDetail.publishTime | formatTime('yyyy-MM-dd HH:mm:ss') }}</p>
            <el-divider />
            <div v-html="currentDetail.content" class="content-detail" />
        </div>
        <homefoot />
    </div>
</template>

<script>
import homeHead from '../../components/homeLayout/homeHead';
import homeFoot from '../../components/homeLayout/homeFoot';

export default {
    name: 'noticedetail',
    components: {
        homehead: homeHead,
        homefoot: homeFoot
    },
    data(){
        return {
            currentDetail: {

            },
            showNoticeDetail: false
        };
    },
    watch: {
        $route: {
            handler(newValue) {
                if (newValue.query.id) {
                    this.getNoticeInfoById(newValue.query.id);
                }
            },
            deep: true,
            immediate: true
        }
    },
    methods: {
        async getNoticeInfoById(id){
            const data = await this.$request({
                url: '/api/notice/getNoticeInfoById',
                origin: true,
                params: {
                    id
                }
            });
            if(data){
                this.showNoticeDetail = true;
                this.currentDetail = data;
            }
        }
    }
};
</script>
<style lang="scss">
.notice-detail{
    table {
        border-top: 1px solid #ccc;
        border-left: 1px solid #ccc;
    }
    table td,
    table th {
        border-bottom: 1px solid #ccc;
        border-right: 1px solid #ccc;
        padding: 6px 5px;
        text-align: center;
    }
}
.content-detail {
    h1,h2,h3,h4,h5,h6 {
        margin: 8px 0;
    }
    h1,h2,h3,h4,h5,h6,p{
        font-weight: normal;
        font-size: 14px;
        line-height: 2;
    }
}
</style>

<style lang="scss" scoped>
.wrapper {
    background: #FFFFFF;
    margin: -16px;
    width: 100%;
    position: absolute;
   .home-breadcrumb{
       width: 800px;
       margin: 15px auto;
    }
    .notice-detail{
        background: #FFFFFF;
        width: 800px;
        margin: 15px auto 30px;
        border: 1px solid #E3E3E3;
        border-radius: 4px;
        padding: 20px 30px;
        flex: 1;
    }
    .notice-detail-title{
        font-size: 16px;
        color: #333333;
        font-weight: bold;
    }
}

</style>
