import { render, staticRenderFns } from "./noticedetail.vue?vue&type=template&id=2ca024f6&scoped=true&"
import script from "./noticedetail.vue?vue&type=script&lang=js&"
export * from "./noticedetail.vue?vue&type=script&lang=js&"
import style0 from "./noticedetail.vue?vue&type=style&index=0&lang=scss&"
import style1 from "./noticedetail.vue?vue&type=style&index=1&id=2ca024f6&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "2ca024f6",
  null
  
)

export default component.exports