<template>
    <div class="zk-help">
        <div v-html="doc" />
    </div>
</template>

<script>
const requestUrl = '//cms-docs.kaola.com/publishModule/getPublishContentByKey.html?key=';
export default {
    data() {
        return {
            doc: ''
        };
    },

    watch: {
        $route: {
            handler(to) {
                this.doc = '';
                if(to.query.key) {
                    this.getHelpDoc(to.query.key);
                }
            },
            immediate: true
        }
    },

    methods: {
        async getHelpDoc(key) {
            this.doc = '';
            const result = await this.$request({
                url: `${requestUrl}${key}`,
                toast: false,
                origin: true
            });
            if(result.code === 200 && result.body) {
                this.doc = result.body.content || '';
            }
        }
    }
};
</script>

<style lang="scss">
.zk-help {
    padding: 16px;
    background: #fff;
    overflow: scroll;
    min-height: 100%;
}
</style>
