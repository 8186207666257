<template>
    <div class="home">
        <homehead />
        <div class="home-breadcrumb">
            <el-breadcrumb separator="/">
                <el-breadcrumb-item :to="{ path: '/' }">首页</el-breadcrumb-item>
                <el-breadcrumb-item><a href="/notice">公告</a></el-breadcrumb-item>
            </el-breadcrumb>
        </div>
        <div class="notice-List" v-show="!showNoticeDetail">
            <div v-for="(v,index) in noticeList" :key="index" class="notice" @click="isshowDetail(v)">
                <div class="notice-top">
                    <span class="notice-title">{{ v.title }}</span>
                    <span class="notice-time">{{ v.publishTime | formatTime('yyyy-MM-dd HH:mm:ss') }}</span>
                </div>
                <div class="notice-bottom">
                    <span class="notice-content" v-html="v.content" />
                    <span class="go-detail" @click="isshowDetail">查看详情</span>
                </div>
            </div>
        </div>

        <div class="pagination" v-if="noticeList && noticeList.length > 0 && !showNoticeDetail">
            <pagination @size-change="handleSizeChange"
                        @current-change="handleCurrentChange"
                        :current-page="form.pageIndex"
                        :page-size="form.pageSize"
                        :pageSizes="[10,20,30,40]"
                        :total="pagination.maxRowCount"
            />
        </div>
        <homefoot />
    </div>
</template>

<script>
import homeHead from '../../components/homeLayout/homeHead';
import homeFoot from '../../components/homeLayout/homeFoot';
import pagination from '../../components/common/pagination.vue';


export default {
    components: {
        homehead: homeHead,
        pagination,
        homefoot: homeFoot
    },
    data(){
        return {
            noticeList: [],
            pagination: {},
            form: {
                pageIndex: 1,
                pageSize: 10
            },
            showNoticeDetail: false,
            currentDetail: {}
        };
    },
    async created(){
        await this.getNoticeInfo();
    },
    watch: {
        $route: {
            handler(newValue) {
                if (newValue.query.title) {
                    this.showNoticeDetail = true;
                    this.currentDetail = newValue.query;
                }
            },
            deep: true,
            immediate: true
        }
    },
    methods: {
        async getNoticeInfo(){
            const data = await this.$request({
                url: '/api/notice/getNoticeInfo',
                origin: true,
                params: this.form
            });
            if(data.list){
                this.pagination = data.pagination;
                this.noticeList = data.list;
            }

        },
        handleSizeChange(size){
            this.form.pageIndex = 1;
            this.form.pageSize = size;
            this.getNoticeInfo();
        },
        handleCurrentChange(p) {
            this.form.pageIndex = p;
            this.getNoticeInfo();
        },
        isshowDetail(v){
            this.$router.push({
                path: '/noticedetail',
                query: 
                {
                    id: v.id
                }
            });
           
        }
    }

};
</script>
<style lang="scss">
.notice-content{
    *{
        display: inline;
        margin: 0;
        padding: 0; 
        overflow: hidden;
        white-space: nowrap;text-overflow: ellipsis;
       
    }
    span,p,h1,h2,h3,h4,h5,h6{
         text-decoration-line: none !important;
        font-style: normal !important;
         font-weight: 400 !important;
         color: black !important;
        font-size: 15px !important;
        background-color:white !important;
    }
    img{
        display: none;
    }
    br{
        display: none;
    }

}
</style>

<style lang="scss" scoped>
*{
    background: #FFFFFF;
    tbody{
        border: 1px solid gray;
    }
}
 .home{
        margin: -16px;
        width: 100%;
        height: 100%;
        position: absolute;
        .home-breadcrumb{
            margin: 11px 300px;
        }
        .notice-List{
            padding: 0 300px;
        }
        .notice{
            background: #FFFFFF;
            height: 85px;
            margin: 11px auto;
            border: 1px solid #E3E3E3;
            border-radius: 4px;
            border-radius: 4px;
            color: #333333;
        }
        .notice:hover{
            border: 1px solid #FF0000;
        }
        .notice-top{
            display: flex;
            justify-content: space-between;
            align-items: baseline;
            margin: 20px 30px;
            margin-bottom: 0;
        }
        .notice-bottom{
             display: flex;
            justify-content: space-between;
            align-items: flex-start;
            margin: 5px 30px;
            min-height: 30px;
        }
        .notice-title{
            font-size:16px; width: 250px;
            overflow: hidden;
            white-space: nowrap;text-overflow: ellipsis;
        }
        .notice-content{
            width: 300px;
            overflow: hidden;
            font-size: 13px;
            color: #333333;
            min-height:20px;
            img{
                display: none;
            }
        }
        .go-detail{
            color: #FF0000;
            cursor:pointer;
        }
        .notice-time{
            font-size: 13px;
            color: #666666;
            text-align: right;
        }
        .notice-content{
            overflow: hidden;
        white-space: nowrap;text-overflow: ellipsis;
        }
        .detail_content{
            width: 100px;
            overflow: hidden;
            white-space: nowrap;text-overflow: ellipsis;
        }
        .el-divider {
            background-color: #DCDFE6;
            position: relative;
        }
        .pagination{
            background: #FFFFFF;
            padding: 10px 0;
            text-align: center;
        }
 }
</style>